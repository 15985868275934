import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";

import { Responsive, MobileOnly, DesktopOnly } from "../../../../../layout";
import { getItemName, QUESTION_RESULT_STATUS } from "../../../../../util/common";

import { ConditionIcon, GalleryButton, Expandable } from "../../../../../components";
import { TYRE_POSITION } from "../../../../../util/tyre";

import "./index.scss";

function filterWorkingsItemsWithRemarks(workingItems) {
  return workingItems.filter(
    r =>
      !(
        (TYRE_POSITION.IS_TYRE(r.tyre_position) && r.tyre_replacements.some(r => r.mechanic_fixed)) ||
        r.question_result_status !== QUESTION_RESULT_STATUS.OK_WITH_REMARKS ||
        r.mechanic_fixed
      )
  );
}

class WorkingItems extends Component {
  render() {
    let { workingItems, showMobile, t } = this.props;

    if (workingItems.length < 1) return null;

    const withRemarks = [],
      withoutRemarks = [];

    workingItems.forEach(item => {
      if (item.question_result_status === QUESTION_RESULT_STATUS.OK_WITH_REMARKS) withRemarks.push(item);
      else withoutRemarks.push(item);
    });

    return (
      <Responsive showMobile={showMobile}>
        <Grid className="working-items-container component-container">
          <Grid.Row computer={16} className="working-items-heading">
            <FontAwesomeIcon icon={faCheckCircle} className="working-items-heading-icon" />
            <h4 className="working-items-heading-title">{t("cc_checked_working_properly").message || "Checked items working properly"}</h4>
          </Grid.Row>

          {withRemarks.length > 0 && (
            <Grid className="working-items-with-remarks" verticalAlign="middle">
              <DesktopOnly>
                <Grid.Row>
                  <Grid.Column width={7} className="working-items-column-title">
                    {t("cc_item_name").message || "ITEM NAME"}
                  </Grid.Column>
                  <Grid.Column width={9} className="working-items-column-title">
                    {t("cc_remarks").message || "REMARKS"}
                  </Grid.Column>
                </Grid.Row>
              </DesktopOnly>

              {withRemarks.map((result, index) => (
                <Grid.Row key={result.question_result_id * 10 + result.tyre_position} className="working-items-row">
                  <Grid.Column computer={7} mobile={16}>
                    <DesktopOnly>
                      <ConditionIcon status={0} />
                    </DesktopOnly>
                    <span className="working-items-title">{getItemName(this.props.t, result)}</span>
                  </Grid.Column>
                  {(result.tyre_profile || result.description) && (
                    <Grid.Column computer={7} mobile={16} className="working-items-remarks">
                      {result.tyre_profile && <Expandable text={result.tyre_profile + "mm"} />}
                      <Expandable text={result.description} />
                    </Grid.Column>
                  )}

                  <Grid.Column computer={2} mobile={16} className="working-items-images">
                    <GalleryButton images={result.images} videos={result.video} />
                  </Grid.Column>
                </Grid.Row>
              ))}
            </Grid>
          )}

          <DesktopOnly>
            <Grid className="working-items-groups">
              <Grid.Column width={8}>
                <Grid.Row key={"evengroup_name"}>
                  {withoutRemarks.slice(0, Math.ceil(withoutRemarks.length / 2)).map(item => (
                    <div className="working-items-group-name-item-box" key={item.id}>
                      <ConditionIcon status={1} size="small" />
                      <span className="working-items-group-title">{getItemName(this.props.t, item)}</span>
                    </div>
                  ))}
                </Grid.Row>
              </Grid.Column>
              <Grid.Column width={8}>
                <Grid.Row key={"oddgroup_name"}>
                  {withoutRemarks.slice(Math.ceil(withoutRemarks.length / 2)).map(item => (
                    <div className="working-items-group-name-item-box" key={item.id}>
                      <ConditionIcon status={1} size="small" />
                      <span className="working-items-group-title">{getItemName(this.props.t, item)}</span>
                    </div>
                  ))}
                </Grid.Row>
              </Grid.Column>
            </Grid>
          </DesktopOnly>
          {withoutRemarks.length > 0 && (
            <MobileOnly>
              <div className="working-items-no-remarks">
                <span>
                  {withoutRemarks.length} {t("cc_MORE_ITEMS_CHECKED").message || "MORE ITEMS WERE CHECKED AND WITHOUT ISSUES"}
                </span>
              </div>
            </MobileOnly>
          )}
        </Grid>
      </Responsive>
    );
  }
}

const TranslatedWorkingItems = withTranslation()(WorkingItems);

TranslatedWorkingItems.filterWorkingsItems = results => results.filter(r => r.question_result_status < QUESTION_RESULT_STATUS.ADVISED);

TranslatedWorkingItems.filterWorkingsItemsWithRemarks = filterWorkingsItemsWithRemarks;

export default TranslatedWorkingItems;
