import React from "react";
import moment from "moment";
import { Modal, Grid, Icon, Message } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import { faCalendarAlt, faInfoCircle, faMapMarkedAlt } from "@fortawesome/pro-regular-svg-icons";

import { CUSTOMER_COMMUNICATION_STATUSES } from "../../../../util/communication_statuses";

import "./index.scss";

const SuccessMsg = ({ communication, settings, isOpen, onClose, keylocker_pin, acses_pin, acses_locker_name }) => {
  const { t } = useTranslation();
  const { appointment } = communication;

  let mapLink = "";
  if (settings.location_street || settings.location_postal_code || settings.location_city)
    mapLink =
      "https://www.google.com/maps/search/?api=1&query=" +
      settings.location_street +
      ", " +
      settings.location_postal_code +
      ", " +
      settings.location_city +
      ", " +
      settings.location_country;

  let success_title = t("cc_thank_you").message || "Thank you";
  let success_msg = "";

  if (communication.status <= CUSTOMER_COMMUNICATION_STATUSES.ONLINE_CHECKIN_CLOSED) {
    success_title = settings.check_in_success_text;
    success_msg = (t("cc_you_have_successfully_checked_in").message || "You have successfully checked in") + "!";
  } else if (communication.status <= CUSTOMER_COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_CLOSED) {
    success_title = settings.success_text;
    success_msg = (t("cc_we_will_get_to_work_for_you").message || "We will get to work for you") + "!";
  }

  return (
    <Modal className="popup-modal thank-you-container" open={isOpen} size="small" dimmer closeOnDimmerClick={false}>
      <Modal.Content className="modal-content">
        <Grid>
          <Grid.Row>
            <Grid.Column computer={16} style={{ transform: "translateY(20%)" }}>
              <div className="thank-you-icon-container">
                <FontAwesomeIcon icon={faCheckCircle} className="thank-you-icon" style={{ color: settings.color ? settings.color : "#1CC15E" }} />
              </div>
              <div>
                <h2 className="thank-you-title">{success_title}</h2>
                <p className="thank-you-text">{success_msg}</p>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={16}>
              <div className="thank-you-info-container">
                <h4 className="thank-you-info-title">{`${t("cc_your_appointment_info").message || "Your appointment information"}:`}</h4>
                <div className="thank-you-info-content-container">
                  <div className="thank-you-info-text">
                    <FontAwesomeIcon icon={faCalendarAlt} style={{ color: settings.color }} />
                    <span className="thank-you-info-text-data">
                      {appointment.due_in
                        ? moment(appointment.due_in)
                            .format("DD-MM-YYYY HH:mm")
                            .replace(" 00:00", "")
                        : moment(appointment.time_car_app).format("DD-MM-YYYY")}
                    </span>
                  </div>
                  <div className="thank-you-info-text">
                    <FontAwesomeIcon icon={faInfoCircle} style={{ color: settings.color }} />
                    <span className="thank-you-info-text-data">{appointment.wo_nr}</span>
                  </div>
                  <div className="thank-you-info-text">
                    <FontAwesomeIcon icon={faMapMarkedAlt} style={{ color: settings.color }} />
                    <span className="thank-you-info-text-data">
                      {settings.dealer_name}, {settings.location_name}
                    </span>
                  </div>
                  {mapLink && (
                    <div className="thank-you-info-text">
                      <a href={mapLink} rel="noopener noreferrer" target="_blank">
                        <img alt="Google maps" src="https://developers.google.com/maps/images/maps-icon.svg" />
                        Google maps
                      </a>
                    </div>
                  )}
                </div>
              </div>
              {keylocker_pin && (
                <div className="keylocker-pin-code-container">
                  <Message>
                    <div>{t("cc_keylocker_skip_reception_msg").message || "Skip the reception queue by using our automated key safe!"}</div>
                    <div>
                      <span>{`${t("cc_keylocker_skip_drop_off_with_pin_code").message || "Simply drop off your car keys with this PIN code"}: `}</span>
                      <span className="pin-code">
                        {keylocker_pin.toString().substr(0, 3)} {keylocker_pin.toString().substr(3, 6)}.
                      </span>
                    </div>

                    <div>{t("cc_keylocker_please_note_msg").message || "Please note, the indoor locker is available only during opening hours."}</div>
                  </Message>
                </div>
              )}

              {acses_pin && (
                <div className="keylocker-pin-code-container">
                  <Message>
                    {`${t("cc_keylocker_pin_code_message").message ||
                      "Skip the reception counter by dropping your car keys in our automated key locker with this PIN code"} `}
                    <br />
                    {`Keylocker: ${acses_locker_name}`}
                    <h3>{`${acses_pin}`}</h3>
                  </Message>
                </div>
              )}
              <div className="thank-you-success-btn-container">
                <button className="thank-you-success-btn-large" onClick={onClose} style={{ backgroundColor: settings.color ? settings.color : "#1CC15E" }}>
                  {t("cc_SUCCESS").message || "SUCCESS"}
                  <Icon name="check" className="thank-you-success-check" />
                </button>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  );
};

export default SuccessMsg;
