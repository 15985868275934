import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { SCREENS } from "./util/screens";

import { InfoScreen, ScheduledServicesScreen, OptionalItemsScreen, OtherItemsScreen, SignatureScreen, ThankYouScreen } from "./screens";

import Service from "./service";

class DeskCommunication extends Component {
  constructor(props) {
    super(props);

    const { communication } = props;

    const agreements = communication.agreements.filter(a => (communication.is_checking_out ? a.visible_desk_check_out : a.visible_desk_check_in));
    const declinedInterventionIds =
      communication.appointment.interventions?.reduce((acc, curr) => {
        if (!curr.customer_ok || (curr.is_declined_by_customer && !curr.mechanic_fixed)) acc.push(curr.id);
        return acc;
      }, []) || [];

    this.state = {
      currentScreen: SCREENS.FIRST_SCREEN,
      communication_status: communication.status,
      approvedMandatoryAgreements:
        agreements.length === 0 || !agreements.some(a => (communication.is_checking_out ? !a.optional_desk_check_out : !a.optional_desk_check_in) && !a.accepted),
      agreements,
      showAgreementError: false,
      declinedInterventionIds,
      criticalItems: communication.results ? OptionalItemsScreen.filterCriticalItems(communication.results) : [],
      advisedItems: communication.results ? OptionalItemsScreen.filterAdvisedItems(communication.results) : [],
      workingItems: OtherItemsScreen.filterWorkingsItems(communication.results, props.t),
      notWorkedOnItems: OtherItemsScreen.filterNotWorkedOnItems(communication.results),
    };
  }

  handleGoToNextScreen = () => {
    const { currentScreen, criticalItems, advisedItems, workingItems, notWorkedOnItems } = this.state;
    const { interventions } = this.props.communication.appointment;
    let shift = 1;

    if (currentScreen + shift === SCREENS.SCHEDULED_SERVICES && !interventions.length) ++shift;
    if (currentScreen + shift === SCREENS.CRITICAL_ITEMS && !criticalItems.length) ++shift;
    if (currentScreen + shift === SCREENS.ADVISED_ITEMS && !advisedItems.length) ++shift;
    if (currentScreen + shift === SCREENS.OTHER_ITEMS && !workingItems.length && !notWorkedOnItems.length) ++shift;

    this.setState(prevState => ({
      currentScreen: prevState.currentScreen + shift,
    }));
  };

  handleGoToPreviousScreen = () => {
    const { currentScreen, criticalItems, advisedItems, workingItems, notWorkedOnItems } = this.state;
    const { interventions } = this.props.communication.appointment;
    let shift = 1;

    if (currentScreen - shift === SCREENS.OTHER_ITEMS && !workingItems.length && !notWorkedOnItems.length) ++shift;
    if (currentScreen - shift === SCREENS.ADVISED_ITEMS && !advisedItems.length) ++shift;
    if (currentScreen - shift === SCREENS.CRITICAL_ITEMS && !criticalItems.length) ++shift;
    if (currentScreen - shift === SCREENS.SCHEDULED_SERVICES && !interventions.length) ++shift;

    this.setState(prevState => ({
      currentScreen: prevState.currentScreen - shift,
    }));
  };

  handleAgreed = id => {
    let { agreements } = this.state;
    const { communication } = this.props;

    let agreement = agreements.find(a => a.id === id);
    if (agreement) {
      agreement.accepted = !agreement.accepted;
      this.setState({
        agreements: [...agreements],
        approvedMandatoryAgreements: !agreements.some(a => (communication.is_checking_out ? !a.optional_desk_check_out : !a.optional_desk_check_in) && !a.accepted),
      });
    }
  };

  handleSendAnswer = (signature_url, signature_name) => {
    let { agreements, declinedInterventionIds: declined_intervention_ids, criticalItems, advisedItems, workingItems, notWorkedOnItems } = this.state;

    const payload = {
      dc_key: this.props.routeKey,
      agreement_ids: agreements.filter(a => a.accepted).map(a => a.id),
      signature_url,
      signature_name,
      declined_intervention_ids,
      results: [...criticalItems, ...advisedItems, ...workingItems, ...notWorkedOnItems],
    };

    return Service.sendAnswer(payload);
  };

  handleShowAgreementError = showAgreementError => {
    this.setState({ showAgreementError });
  };

  handleUpdateDeclinedInterventionIds = (evt, interventionID) => {
    evt.stopPropagation();

    this.setState(({ declinedInterventionIds }) => {
      if (this.state.declinedInterventionIds.includes(interventionID)) return { declinedInterventionIds: declinedInterventionIds.filter(id => id !== interventionID) };

      return { declinedInterventionIds: [...declinedInterventionIds, interventionID] };
    });
  };

  handleCriticalItemsChanged = criticalItems => {
    this.setState({ criticalItems: [...criticalItems] });
  };

  handleAdvisedItemsChanged = advisedItems => {
    this.setState({ advisedItems: [...advisedItems] });
  };

  render() {
    const {
      currentScreen,
      agreements,
      approvedMandatoryAgreements,
      declinedInterventionIds,
      showAgreementError,
      criticalItems,
      advisedItems,
      workingItems,
      notWorkedOnItems,
    } = this.state;
    const { communication, settings, routeKey } = this.props;

    const readOnly = communication.is_receptionist;

    switch (currentScreen) {
      default:
      case SCREENS.INFO:
        return <InfoScreen communication={communication} settings={settings} onGoToNextScreen={this.handleGoToNextScreen} />;

      case SCREENS.THANK_YOU:
        return <ThankYouScreen />;

      case SCREENS.SCHEDULED_SERVICES:
        return (
          <ScheduledServicesScreen
            communication={communication}
            settings={settings}
            agreements={agreements}
            onAgreed={this.handleAgreed}
            readOnly={readOnly || communication.is_checking_out}
            approved={approvedMandatoryAgreements}
            showAgreementError={showAgreementError}
            onGoToNextScreen={this.handleGoToNextScreen}
            onGoToPreviousScreen={this.handleGoToPreviousScreen}
            declinedInterventionIds={declinedInterventionIds}
            onUpdateDeclinedInterventionIDs={this.handleUpdateDeclinedInterventionIds}
          />
        );

      case SCREENS.CRITICAL_ITEMS:
        return (
          <OptionalItemsScreen
            communication={communication}
            question_status={OptionalItemsScreen.QUESTION_STATUS.CRITICAL}
            readOnly={readOnly || communication.is_checking_out}
            settings={settings}
            showMobile={true}
            items={criticalItems}
            showNotFixedButton={true}
            updateItems={this.handleCriticalItemsChanged}
            onGoToNextScreen={this.handleGoToNextScreen}
            onGoToPreviousScreen={this.handleGoToPreviousScreen}
          />
        );

      case SCREENS.ADVISED_ITEMS:
        return (
          <OptionalItemsScreen
            communication={communication}
            question_status={OptionalItemsScreen.QUESTION_STATUS.ADVISED}
            readOnly={readOnly || communication.is_checking_out}
            settings={settings}
            showMobile={true}
            items={advisedItems}
            showNotFixedButton={true}
            updateItems={this.handleAdvisedItemsChanged}
            onGoToNextScreen={this.handleGoToNextScreen}
            onGoToPreviousScreen={this.handleGoToPreviousScreen}
          />
        );

      case SCREENS.OTHER_ITEMS:
        return (
          <OtherItemsScreen
            communication={communication}
            workingItems={workingItems}
            notWorkedOnItems={notWorkedOnItems}
            onGoToNextScreen={this.handleGoToNextScreen}
            onGoToPreviousScreen={this.handleGoToPreviousScreen}
          />
        );

      case SCREENS.SIGNATURE:
        return (
          <SignatureScreen
            optionalItems={[...criticalItems, ...advisedItems]}
            routeKey={routeKey}
            readOnly={readOnly}
            communication={communication}
            onSendAnswer={this.handleSendAnswer}
            onGoToPreviousScreen={this.handleGoToPreviousScreen}
            onGoToNextScreen={this.handleGoToNextScreen}
            settings={settings}
            agreements={agreements}
            onAgreed={this.handleAgreed}
            approved={approvedMandatoryAgreements}
            showAgreementError={showAgreementError}
            onShowAgreementError={this.handleShowAgreementError}
            declinedInterventionIds={declinedInterventionIds}
            onUpdateDeclinedInterventionIDs={this.handleUpdateDeclinedInterventionIds}
          />
        );
    }
  }
}

export default withRouter(withTranslation()(DeskCommunication));
