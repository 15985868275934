import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-regular-svg-icons";

import { ConditionIcon, GalleryButton, Expandable } from "../../../../../components";
import { Responsive, DesktopOnly, MobileOnly } from "../../../../../layout";
import { TYRE_POSITION } from "../../../../../util/tyre";
import { round2, getItemName, QUESTION_RESULT_STATUS, getImageAndVideoFileTypes } from "../../../../../util/common";

import "./index.scss";

class NotFixedItems extends Component {
  renderIntervention = (item, id, description, price, status, profile) => {
    const { images, videos } = getImageAndVideoFileTypes(item.attachments);

    return (
      <Grid.Row key={id} className="not-fixed-row">
        <Grid.Column computer={5} widescreen={6} mobile={16}>
          <div className="interventions-row-title">
            <ConditionIcon status={status || 3} className="not-fixed-status-icon" />

            <Expandable className="not-fixed-title" text={getItemName(this.props.t, item)} />
          </div>
        </Grid.Column>

        <Grid.Column computer={5} widescreen={6} mobile={16} className="not-fixed-description">
          {profile && <Expandable text={profile + "mm"} />}

          <Expandable text={description} />
        </Grid.Column>

        {price && (
          <MobileOnly>
            <Grid.Column width={6}>{price}</Grid.Column>
          </MobileOnly>
        )}
        <DesktopOnly>
          <Grid.Column width={3} className="interventions-price">
            {price}
          </Grid.Column>
        </DesktopOnly>

        <Grid.Column computer={1} mobile={4}>
          <GalleryButton images={images} videos={videos} />
        </Grid.Column>
      </Grid.Row>
    );
  };

  renderPrice = price => {
    const { include_vat, vat } = this.props.settings;

    if (!(price > 0.01)) return null;

    if (include_vat) price = price * (1 + vat / 100);

    return <span className="not-fixed-price">€ {round2(price).toFixed(2)}</span>;
  };

  renderNotFixedItems = interventions => {
    if (!Array.isArray(interventions)) return null;

    return interventions.map(i => this.renderIntervention(i, i.id, i.description, this.renderPrice(i.price), i.question_result_status));
  };

  render() {
    const { notFixedItems, showMobile, t } = this.props;

    if (notFixedItems.length < 1) return null;

    return (
      <Responsive showMobile={showMobile}>
        <Grid verticalAlign="middle" className="repair-overview-v2 not-fixed-container component-container">
          <Grid.Row className="not-fixed-heading">
            <Grid.Column width={16}>
              <FontAwesomeIcon icon={faTimesCircle} className="not-fixed-heading-icon" />
              <h4 className="not-fixed-heading-title">{t("cc_items_not_fixed").message || "Items not fixed"}</h4>
            </Grid.Column>
          </Grid.Row>
          {this.renderNotFixedItems(notFixedItems)}
        </Grid>
      </Responsive>
    );
  }
}

const TranslatedNotFixedItems = withTranslation()(NotFixedItems);

TranslatedNotFixedItems.filterNotFixedItems = results =>
  results.filter(r => {
    if (TYRE_POSITION.IS_TYRE(r.tyre_position) && r.tyre_replacements?.length > 0 && !r.tyre_replacements.some(tr => tr.mechanic_fixed)) {
      if (r.tyre_replacements.length === 1) r.price = r.tyre_replacements[0].price;
      else r.price = -1;

      return true;
    }

    return (r.question_result_status === QUESTION_RESULT_STATUS.ADVISED || r.question_result_status === QUESTION_RESULT_STATUS.NECESSARY) && !r.mechanic_fixed;
  });
export default TranslatedNotFixedItems;
